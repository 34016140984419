import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useParams } from "react-router-dom";
// import { Button } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBookList } from "../../store/MyBooks";

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const MaterialTabs = (props) => {
  console.log("DATA", props.datas);
  const [value, setValue] = useState(0);
  const [active, setActive] = useState(0);
  const [len, setLen] = useState(props.datas.length);
  const [booktype, setbooktype] = useState("");
  const [data, setData] = useState(props.datas[0].url);
  const [full, setFull] = useState(false);

  let { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    setData(props.datas[0].url);
    console.log("---props.datas------", props.datas[0].url);
    console.log("props.id", id);
  }, [props.sem]);

  useEffect(() => {
    props.size(true);
  }, [full]);

  const navigate = useNavigate();
  const handleClick = (url, index, data, booktype) => {
    setbooktype(booktype);

    setActive(index);
    setData(url);
  };

  const handleFullScreen = () => {
    setFull(true);
  };

  const handleFullScreen1 = () => {
    if (iframeref.current) {
      console.log("fullscreen");

      iframeref.current.requestFullscreen();
    }
  };

  const iframeref = useRef();

  console.log(data);
  const handleFull = () => {
    dispatch(setBookList(data));
    navigate(`/home/view-book/${id}`);
  };

  return (
    <>
      <div className="flex flex-col !top-3 h-[100%] my-[1rem] p-[1rem] w-full">
        {props.datas.map((data, index) => (
          <span
            onClick={() =>
              handleClick(data.url, data.index, data, data.booktype)
            }
          >
            <Button
              className={`${
                active === data.index
                  ? "!bg-[#1d7b85] !shadow-md "
                  : "!bg-inherit"
              } !p-[0.8rem] !mx-2`}
              variant="contained"
            >
              {data.booktype}
            </Button>
          </span>
        ))}

        {/* {props.datas.length === 0 ? ( */}

        <div className="mt-[1.5rem] sm:mt-[2rem] relative">
          <div className="ml-[30%] sm:ml-[45%] absolute z-10 mt-1">
            <Button
              onClick={handleFull}
              className={`!text-blue-600 !bg-white `}
              variant="outlined"
            >
              Full Screen
            </Button>
          </div>

          <iframe
            id="iframe"
            loading="eager"
            // onload={onMyFrameLoad}
            src={data}
            allow="fullscreen"
            ref={iframeref}
            className={`${"!h-[70vh] !w-full"}`}
          />
        </div>
      </div>
    </>
  );
};

export default MaterialTabs;
