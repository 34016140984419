import React, { useLayoutEffect } from "react";
// import Breadcrumb from "../component/Breadcrumb/Breadcrumb";
import AdminNavbar from "../component/Navbar/AdminNavbar";
import UserTable from "../component/DataTable/UserTable";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { useState } from "react";
import AddInfo from "../component/AddForm/AddInfo";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Users = ({ fetchdata }) => {
  const [open, setopen] = useState(false);
  // const [data, setdata] = useState("");

  const handlesubject = () => {
    setopen(false);
  };

  useLayoutEffect(() => {}, []);

  const handleadduser = () => {
    setopen(true);
  };

  return (
    <>
      <div>
        <AdminNavbar />
      </div>

      <div className="flex justify-between mt-[10vh]">
        <div>
          {/* <div>
            <Breadcrumb />
          </div> */}
        </div>
        <div className="flex !justify-center !items-center !p-[2rem]">
          <Button
            variant="contained"
            onClick={handleadduser}
            className="!bg-[#1d7b85] "
          >
            Add User
          </Button>
          <Dialog
            PaperProps={{
              sx: {
                width: "29rem",
              },
            }}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
          >
            <AddInfo className={"relative"} closeform={setopen} />

            <CloseIcon
              className=" absolute bottom-[95%] left-[90%] cursor-pointer"
              onClick={handlesubject}
            />
          </Dialog>
        </div>
      </div>
      <div>
        <div className="p-2">
          <UserTable />
        </div>
      </div>
    </>
  );
};
export default Users;
