import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import eupheus_logo from "../../assets/eupheus_logo.png";
import { Tab, Tabs } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Person2Icon from "@mui/icons-material/Person2";
import PasswordIcon from "@mui/icons-material/Password";
import LogoutIcon from "@mui/icons-material/Logout";
import DrawerComponent from "../DrawerComponent/UserDrawer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import localinstance from "../../localinstance";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { TextField } from "@mui/material";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import { Button } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

export default function Navbar() {
  const [value, setValue] = React.useState();
  const [page, setPage] = useState(0);
  const [Useremail, setUseremail] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [searchRow, setSearchRow] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [fetchdata, setfetchdata] = useState([]);
  const theme = useTheme();
  // console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  let { id } = useParams();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const handleSearch = (val) => {
    setSearchVal(val.trim());
  };

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const dispatch = useDispatch();

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const fetch = async () => {
    const res = await localinstance({
      url: `book/get/all`,
      method: "GET",
      headers: {
        Authorization: `${Cookies.get("token")}`,
        // accesskey: `auth74961a98ba76d4e4`,
      },
    });
    let data = res.data.message;
    console.log("fetchBOOK-------", res.data.message);
    setfetchdata(res.data.message);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const filterTable = () => {
    // console.log(fetchdata);
    setPage(0);
    let tempArr = [];
    for (let ele of fetchdata) {
      // console.log(ele.series);
      let bookname = ele.name.toLowerCase();
      if (bookname.indexOf(searchVal.toLowerCase()) > -1) {
        tempArr.push(ele);
      }
    }
    // console.log(tempArr);
    setSearchRow([]);
    if (tempArr.length === 0) {
      alert("No data Found");
    } else {
      setSearchRow(tempArr);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  // };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();

  const logout = () => {
    Cookies.remove("id");
    Cookies.remove("token");
    Cookies.remove("role");
    Cookies.remove("admin");
    Cookies.remove("user");

    dispatch(authActions.logout());
    navigate("/");
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={logout}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <LogoutIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box>
      <AppBar position="fixed" className="!bg-[#aeaeaf]">
        <Toolbar
          className=" py-3 "
          sx={{ marginLeft: "8vw", marginRight: "8vw" }}
        >
          <img
            className="w-[220px]  rounded-sm"
            alt="logo"
            src="https://skool.ai/bucket/assets/images/logo/eupheus.png"
          />

          {isMatch ? (
            <DrawerComponent />
          ) : (
            <>
              <div className="flex justify-between w-full">
                <div className="flex mx-[2.5rem] ">
                  <NavLink
                    key={1}
                    className={({ isActive }) =>
                      isActive
                        ? "activeNav !rounded-[30px]"
                        : "!rounded-[30px] inactive"
                    }
                    to="/home"
                  >
                    <Button className="!text-white   !text-lg !px-6   !py-3   ">
                      Home
                    </Button>
                  </NavLink>
                  <NavLink
                    key={2}
                    className={({ isActive }) =>
                      isActive
                        ? "activeNav !rounded-[30px]"
                        : "!rounded-[30px] inactive"
                    }
                    to="/all_books"
                  >
                    <Button className="!text-white  !text-lg !px-8   !py-3 !rounded-[30px]">
                      Library
                    </Button>
                  </NavLink>
                </div>
              </div>
              <div className="flex justify-around px-7 min-w-96">
                <TextField
                  id="search-bar"
                  className="text"
                  onInput={(e) => {
                    handleSearch(e.target.value);
                  }}
                  label="Enter Search Value"
                  variant="outlined"
                  placeholder="Search..."
                  size="small"
                  type="search"
                />
                <div className="bg-[#aeaeaf]">
                  <IconButton
                    type="submit"
                    aria-label="search"
                    onClick={filterTable}
                  >
                    <SearchIcon style={{ fill: "blue" }} />
                  </IconButton>
                </div>
              </div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
                <input
                  type="button"
                  value={Useremail}
                  onChange={(e) => setUseremail(e.target.value)}
                  className="bg-[#aeaeaf] text-base"
                />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </Box>
  );
}
