import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Cookies from "js-cookie";

export default function Loader({ status }) {
  return (
    <>
      {Cookies.get("token") ? (
        Cookies.get("role") === "Admin" ? (
          <>
            <Box className="flex justify-center ">
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            <Box className="flex justify-center items-center mt-[6rem]">
              <CircularProgress />
            </Box>
          </>
        )
      ) : (
        <>
          <Box className="flex ">
            <CircularProgress />
          </Box>
        </>
      )}
    </>
  );
}
